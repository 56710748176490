<template>
  <div>
    <v-card class="rounded-b-0">
      <v-card-title class="py-2 mb-2">Interested People List</v-card-title>
      <v-card-subtitle class="font-weight-medium">{{ project.title }}</v-card-subtitle>
      <v-divider></v-divider>
    </v-card>
    <div v-if="project.interested.length > 0">
      <v-card class="rounded-t-0" v-for="(item, i) in project.interested" :key="i" :to="{ name: 'profile', params: { id: item.user.ID } }" :tile="i !== project.interested.length - 1">
        <v-list three-line>
          <v-list-item>
            <v-list-item-avatar size="60">
              <v-img :src="item.user.data.meta.et_avatar_url"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="d-flex align-center text-h6">{{ `${item.user.data.meta.first_name} ${item.user.data.meta.last_name}` }} <v-icon color="success" class="ml-1" v-show="item.user.data.meta.final_status === 'confirm'">mdi-check-decagram</v-icon></v-list-item-title>
              <v-list-item-subtitle class="my-1 font-weight-medium" v-if="item.user.data.profile && item.user.data.profile.college[0]">{{ item.user.data.profile.college[0].name }}</v-list-item-subtitle>
              <v-list-item-subtitle class="my-1 font-weight-medium">{{ item.user.data.meta.location }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item>
            <v-list-item-content class="pa-0">
              <div v-if="item.user.data.profile">
                <v-chip v-for="(skill, i) in item.user.data.profile.skill" :key="i" class="mr-2 mb-2" small outlined label color="info">{{ skill.name }}</v-chip>
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <!--        <v-card-subtitle class="pb-1 text&#45;&#45;primary"><span class="text&#45;&#45;secondary">Posted by</span> {{ item.project.authorName }}</v-card-subtitle>-->
        <v-card-text>
          <span class="success--text text-break font-weight-medium">Applied {{ item.date | moment('from', 'now') }}</span>
          <div class="my-3 pl-3 text-h6 font-weight-medium message">{{ item.message }}</div>
        </v-card-text>
        <v-divider v-show="i < project.interested.length - 1"></v-divider>
        <v-divider v-show="i < project.interested.length - 1"></v-divider>
      </v-card>
    </div>
    <div v-else>
      <v-card class="rounded-t-0">
        <v-card-title>
          😐 No applicants yet!
        </v-card-title>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    project: {
      type: Object,
      default: () => {}
    }
  }
}
</script>
<style lang="scss" scoped>
  .message {
    border-left: 3px solid;
  }
</style>
