<template>
  <v-container fluid class="px-1 pa-sm-5 mobile-height">
    <v-card v-if="$apollo.queries.project.loading">
      <v-skeleton-loader type="list-item-two-line" class="mb-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
      <v-divider></v-divider>
      <v-skeleton-loader type="list-item-two-line" class="my-5"></v-skeleton-loader>
    </v-card>
    <interested :project="project" v-if="project" :current-user="currentUser" />
  </v-container>
</template>

<script>
import INTERESTED_ON_PROJECT from '../graphql/queries/interestedOnProject.graphql'
import Interested from '../components/ProjectComponent/Interested'
export default {
  props: {
    currentUser: {
      type: Object,
      default: () => {}
    },
    id: {}
  },
  apollo: {
    project: {
      query: INTERESTED_ON_PROJECT,
      variables () {
        return {
          id: this.id
        }
      }
    }
  },
  components: {
    Interested
  }
}
</script>
